var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"admin-faq-category"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('good-data-table',{ref:"categoryRef",attrs:{"button-label":_vm.$t('Add FAQ Category'),"columns":_vm.faqColumns,"total-column":'response.responseData.total',"data-column":'response.responseData.data',"api-endpoint":'/admin/faq/categories'},on:{"add-new-info":_vm.showAddModal}})],1)],1),_c('b-modal',{attrs:{"no-close-on-backdrop":true,"title":_vm.dataInfo && _vm.dataInfo.id ? 'Edit Category' : 'Add Category',"size":"lg","ok-only":"","ok-title":_vm.$t('Save Changes')},on:{"hidden":function($event){_vm.showItemsModal = false},"ok":_vm.saveData},model:{value:(_vm.showItemsModal),callback:function ($$v) {_vm.showItemsModal=$$v},expression:"showItemsModal"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Category')}},[_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.dataInfo.name),callback:function ($$v) {_vm.$set(_vm.dataInfo, "name", $$v)},expression:"dataInfo.name"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Description')}},[_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.dataInfo.description),callback:function ($$v) {_vm.$set(_vm.dataInfo, "description", $$v)},expression:"dataInfo.description"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Status')}},[_c('b-form-select',{attrs:{"options":[
              {
                text: 'Active',
                value: '1',
              },
              {
                text: 'Inactive',
                value: '0',
              } ]},model:{value:(_vm.dataInfo.status),callback:function ($$v) {_vm.$set(_vm.dataInfo, "status", $$v)},expression:"dataInfo.status"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Picture')}},[_c('image-input',{attrs:{"default-image":_vm.dataInfo.picture},on:{"input-file":_vm.inputFile}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }